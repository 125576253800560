import FileItem from '../FileItem';

type Props = {
    items: any[]
    onItemClick: (arg: string) => void
}

const FileExplorer = ({items, onItemClick}: Props) => {
// const FileExplorer = ({onItemClick}: Props) => {
    return (
        <div style={{
            width: "100%",
            paddingLeft: "30px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "left",
        }}>
                {
                    items.map((item, index) => (
                        <FileItem 
                        key={index} 
                        name={`${item.transactionId}`} 
                        onItemClick={()=>onItemClick(`${item.pdfUrl}`)}/>
                    ))
                }
    </div>
    );
}

export default FileExplorer;
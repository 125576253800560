import Modal from '../../Unknown/Modal'
import { useAppDispatch, useAppSelector } from '../../../modules/store/hooks'
import { modalsSlice } from '../../../modules/store/reducers/modalsSlice'
import { Button, CircularProgress, Typography } from '@mui/material'
import { Box } from '@mui/system';
import { DragEvent, useEffect, useRef, useState } from 'react';
import { eventApi } from '../../../modules/services/eventService';
import pdfIcon from '../../../assets/images/pdfIcon.png'
import translate from '../../../i18n/translate';
type Props = {
  id: number | undefined,
  link: string | null,
  onReceiptChange: (id: number, fileName: string) => void
}

export default function AddPaymentFileModal(props: Props) {
  const dispatch = useAppDispatch()
  const { toggleAddPaymentModal } = modalsSlice.actions
  const visible = useAppSelector((state) => state.modalsReducer.addPayment)

  const inputFile = useRef<HTMLInputElement | null>(null);

  const [
    uploadFile, 
    {isLoading: isFileUploading}
  ] = eventApi.useUploadFileMutation();
  
  
  const [fileName, setFileName] = useState<string | null>(null)

  const [file, setFile] = useState<File | undefined>(undefined)
  const [drag, setDrag] = useState(false)
  function dragStartHandler(e: DragEvent<HTMLDivElement>) {
    e.preventDefault()
    setDrag(true)
  }
  function dragLeaveHandler(e: DragEvent<HTMLDivElement>) {
    e.preventDefault()
    setDrag(false)
  }
  function onDropHandler(e: DragEvent<HTMLDivElement>) {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    onFileUploading(props.id || -1, file)
    setDrag(false)
  }
  function onUploadClick () {
    inputFile?.current?.click();
  }

  function handleExplorerInputChange (event: any) {
    const file = event.target.files[0]
    onFileUploading(props.id || -1, file)
  }

  function onFileUploading (id: number, file: File) {
    const formData = new FormData()
    formData.append('file', file)
    uploadFile({ id: id, data: formData })
    
    setFile(file)
    props.onReceiptChange(id, file.name)
  }

  useEffect(()=> {
    setFileName((props.link || "").substr((props.link || "").lastIndexOf('/') + 1))
  }, [props])
  useEffect(()=> {
    if(file){
      setFileName(file.name)
    }
  }, [file])

  return (
    <Modal
      title={translate('apModalTitle')}
      visible={visible === 'open'}
      style={{maxWidth: '400px'}}
      setVisible={() => dispatch(toggleAddPaymentModal())}
    >
      <input 
        ref={inputFile} 
        onChange={handleExplorerInputChange}
        type="file" 
        name="name" 
        style={{ display: "none" }} 
        accept=".pdf"
        />
      <div style={{
        backgroundColor: "orange", 
        width: "100%",
        padding: "10px",
        }}>
          <Box sx={{
            height: "500px", 
            backgroundColor: "white", 
            display: "flex", 
            justifyContent: "space-around", 
            flexDirection: "column",
            padding: "10px",
            }}>
            <Box sx={{height: "400px", width: "100%", border: "1px solid orange", marginBottom: "10px"}}>
              {
                  isFileUploading ? (
                    <Box 
                    width="100%" 
                    height="100%"
                    display="flex"
                    alignItems="center" 
                    justifyContent="center">
                      <CircularProgress color="warning" size={50}/>
                    </Box>
                  ) : (
                    drag ? (
                      <div 
                      onDragStart={e => dragStartHandler(e)}
                      onDragLeave={e => dragLeaveHandler(e)}
                      onDragOver={e => dragStartHandler(e)}
                      onDrop={e => onDropHandler(e)}
                      style={{width: "100%", height: "100%"}}>
                        <Box sx={{display: "flex", height: "100%", alignItems: "center", justifyContent: "center"}}>
                          <Typography fontSize={30}>{translate('apModalDropFileHere')}</Typography>
                        </Box>
                      </div>
                    ) : (
                      fileName ? 
                      (
                        <div 
                        onDragStart={e => dragStartHandler(e)}
                        onDragLeave={e => dragLeaveHandler(e)}
                        onDragOver={e => dragStartHandler(e)}
                        onDrop={e => onDropHandler(e)}
                        style={{width: "100%", height: "100%"}}>
                          <Box sx={{height: "330px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}} >
                            <img src={pdfIcon} alt={fileName} style={{height: "200px"}}/>
                          </Box>
                          <Typography textAlign="center">{fileName}</Typography>
                        </div>
                      )
                      :
                      (<div 
                      onDragStart={e => dragStartHandler(e)}
                      onDragLeave={e => dragLeaveHandler(e)}
                      onDragOver={e => dragStartHandler(e)}
                      style={{width: "100%", height: "100%"}}>
                        <Box 
                        sx={{
                          display: "flex", 
                          height: "100%", 
                          alignItems: "center", 
                          justifyContent: "center"
                        }}>
                            <Typography fontSize={30}>{translate('apModalDragFileHere')}</Typography>
                        </Box>
                      </div>)
                    )
                  )
              }
            </Box>

            <Box sx={{width: "100%"}}>
              <Button 
              fullWidth 
              variant="outlined" 
              color="warning" 
              onClick={onUploadClick}>
                {(file || props.link) ? translate('apModalReUploadFile') : translate('apModalUploadFile')} 
              </Button>
              {(file || props.link) && (<Button disabled fullWidth variant="outlined" color="warning" sx={{marginTop: "10px"}}>DOWNLOAD FILE</Button>)}
            </Box>
          </Box>
      </div>
    </Modal>
  );
}



import { apiSlice } from "../store/api/adminApi"

// export const deviceApi = createApi({
//     reducerPath: 'deviceApi',
//     baseQuery: fetchBaseQuery({baseUrl: 'https://api.selector.org.il/admin/devices'}),
//     endpoints: (build)=>({
//         fetchDevices: build.query<any, any>({
//             query: () => ({
//                 url: `/list`
//             }),
//         }),
//         deleteDevice: build.mutation({
//             query: (id: string | number) => ({
//                 url: `/disconnect/${id}`,
//                 method: 'DELETE',
//             }),
//         }),
//     })
// })

export const deviceApi = apiSlice.injectEndpoints({
    endpoints: (build)=>({
        fetchDevices: build.query<any, any>({
            query: () => ({
                url: `/admin/devices/list`
            }),
        }),
        deleteDevice: build.mutation({
            query: (id: string | number | null) => ({
                url: `/admin/devices/disconnect/${id}`,
                method: 'DELETE',
            }),
        }),
    })
})
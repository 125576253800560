import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit'
import modalsReducer from './reducers/modalsSlice'
import eventsCountReducer from './reducers/eventsCountSlice'
// import { eventApi } from '../services/eventService'
import { userApi } from '../services/userService'
import { transactionApi } from '../services/transactionService'
import { deviceApi } from '../services/deviceService'
import { statisticApi } from '../services/statisticService'
import authReducer from './reducers/authSlice'
import { authApi } from '../services/authService'
import { eventApi } from '../services/eventService'


const rootReducer = combineReducers({
  auth: authReducer,
  modalsReducer,
  eventsCountReducer,
  [authApi.reducerPath]: authApi.reducer,
  [eventApi.reducerPath]: eventApi.reducer,
  [statisticApi.reducerPath]: statisticApi.reducer,
  [deviceApi.reducerPath]: deviceApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware, 
      eventApi.middleware as any, 
      userApi.middleware, 
      deviceApi.middleware, 
      statisticApi.middleware, 
      transactionApi.middleware
    ),
    devTools: true,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({baseUrl: 'https://api.selector.org.il'}),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (token) => ({
                url: '/admin/auth/login',
                method: 'POST',
                body: token
            })
        }),
        checkToken: builder.mutation({
            query: (token) => ({
                url: `/admin/auth/check`,
                method: 'POST',
                body: {token: token}
            })
        }),
    })
})

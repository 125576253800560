import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
    Headline: {
        '&.MuiTypography-root': {
            fontSize: "22px",
            margin: "0 0 20px 0",
        },
    },
    Wrapper: {
        backgroundColor: "black",
        height: "100vh",
        width: "100%", 
        display: "flex",
        alignItems: "center",
        justifyContent:"center",
    },
    LoginPanel: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent:"center",
        padding: "20px",
        // height: "200px",
        width: "500px", 
        backgroundColor: "white",
    },
}));

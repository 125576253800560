import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EventsScreen from '../../EventsScreen';
import DocumentsScreen from '../../DocumentsScreen';
import ProfilesScreen from '../../ProfilesScreen';
import AppLayout from '../AppLayout';
import LogIn from '../../LogIn';
import ConfirmReceipt from '../../ConfirmReceipt';
import InvoiceReceipt from '../../InvoiceReceipt';
import RequireToken from '../RequireAuth';
const Root: React.FC = () => {
  return (<>
    <AppLayout>
      <Routes>
        <Route path="/login" element={<LogIn />} />
        <Route path="/confirm-receipt" element={<ConfirmReceipt/>} />
        <Route path="/invoice-receipt" element={<InvoiceReceipt/>} />
        <Route element={<RequireToken />}>
          <Route path="/" element={<EventsScreen />} />
          <Route path="/profiles" element={<ProfilesScreen/>} />
          <Route path="/documents" element={<DocumentsScreen/>} />
        </Route>
        
      </Routes>
    </AppLayout>
</>
  );
};

export default Root;

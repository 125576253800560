import { createSlice } from '@reduxjs/toolkit'
import { LOCALES } from '../../../i18n'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    sessionID: null,
    token: null,
    language: LOCALES.HEBREW,
  },
  reducers: {
    setCredentials: (state, action) => {
        const {accessToken, id} = action.payload
        state.sessionID = id
        state.token = accessToken

        if(accessToken && id){
          localStorage.setItem('token', accessToken)
          localStorage.setItem('id', id)
        }
    },
    updateToken: (state, action) => {
        const {accessToken} = action.payload
        state.token = accessToken

        if(accessToken){
          localStorage.setItem('token', accessToken)
        }
    },
    logOut: (state) => {
        state.sessionID = null
        state.token = null

        localStorage.removeItem('token')
        localStorage.removeItem('id')
    },
    toggleLanguage: (state) => {
      state.language = state.language === LOCALES.HEBREW ? LOCALES.ENGLISH : LOCALES.HEBREW
    }
  },
})

export const {setCredentials, logOut, toggleLanguage, updateToken} = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state: any) => state.auth.user
export const selectCurrentToken = (state: any) => state.auth.token
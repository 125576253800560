import { CssBaseline } from '@mui/material';
import { I18Provider } from '../i18n';
import Root from "./Unknown/Root"
import { useAppSelector } from '../modules/store/hooks';

const App: React.FC = () => {
    const language = useAppSelector((state)=>state.auth.language)

    return (
        <I18Provider locale={language}>
            <CssBaseline />
            <Root />
        </I18Provider>
       )
}

export {App}
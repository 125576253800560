import { apiSlice } from "../store/api/adminApi"

export const transactionApi = apiSlice.injectEndpoints({
    endpoints: (build)=>({
        fetchTransactions: build.query<any, any>({
            query: (body: {
                type: string,
                eventId?: number,
                skip: number,
                take: number,
                filter: string
            }) => ({
                //provide ID - temporary unavailable
                url: `/admin/transactions/list/${body.type}?${body.eventId ? `eventId=${body.eventId}&` : ``}skip=${body.skip || 0}&take=${body.take || 10}${body.filter || ""}`
            }),
        }),
        getStatistic: build.query<any, any>({
            query: (body: {
                filter?: string
            }) => ({
                url: `/admin/total/statistic?${body.filter || ""}`
            })
        }),
    })
})
import { DataGrid } from '@mui/x-data-grid';
import translate from '../../../i18n/translate';
import Filter from '../Filter';
import ReplayIcon from '@mui/icons-material/Replay';
import useStyles from './useStyles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Button, Link, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../modules/store/hooks';
import { modalsSlice } from '../../../modules/store/reducers/modalsSlice';
import AddPaymentFileModal from '../../Modals/AddPaymentFileModal';
import { eventApi } from '../../../modules/services/eventService';
import { eventsCountSlice } from '../../../modules/store/reducers/eventsCountSlice';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import toast, { Toaster } from 'react-hot-toast';
import format from 'date-fns/format';

type Props = {
  type?: string,
  count?: number,
}

enum StatusesHighlight {
  active = 'green',
  finished = 'blue',
  deleted = 'red'
}

const EventsDataGrid = (props: Props) => {
    const takeCount = 10;
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
      updateDeletedCount,
      updateHiddenCount,
      updateNotConfirmedCount
    } = eventsCountSlice.actions;
    const {toggleAddPaymentModal} = modalsSlice.actions;
    const [columnSelector, setColumnSelector] = useState(
      {
        withPaymentStatus: true,
        withMoneyStatistic: true,
        statusColumnSelector: {
          isDeletedCheckBox: true,
          isHiddenCheckBox: true, 
          isShowButton: true,
          isRepeatButton: true,
          isConfirmButton: true,
          isDeleteButton: true,
        }
      }
    )
    const [isAnyPutRequestLoading, setIsAnyPutRequestLoading] = useState<boolean>(false)

    const [columns, setColumns] = useState<any[]>([]);
    const [rows, setRows] = useState<any[]>([]);
    const [count, setCount] = useState<number>(0)
    const [filterRequestString, setFilterRequestString] = useState<string | undefined>(undefined);
    const [paymentModalEventData, setPaymentModalEventData] = useState<any>({id: undefined, link: undefined})
    const [changedEventID, setChangedEventID] = useState<any | undefined>(undefined)

    //#region Basic Columns
    const bankAccountInfoColumn = {
      field: 'bankAccountInfo',
      flex: 1.7,
      filtrable: false,
      sortable: true,
      headerName: translate('gridEventsBankAccountInfo'),
      renderCell: (params: any) => {
        return (
          <>{
            params.row.banks.map((item: any, index: any)=>(
              <Box className={classes.StatusCell} key={index}>
                <Typography>
                  <span>{translate('gridEventsBankAccountInfoNumber')}: </span> 
                  {item.bankNumber}
                </Typography>
                <Typography>
                  <span>{translate('gridEventsBankAccountInfoAccountNumber')}: </span> 
                  {item.bankAccountNumber}
                </Typography>
                <Typography>
                  <span>{translate('gridEventsBankAccountFullName')}: </span> 
                  {item.name} {item.surname}
                </Typography>
                <Typography>
                  <span>
                    {item.idCode}
                  </span>
                </Typography>
              </Box>
            ))
          }</>
        );
      },
    }
    const producerInfoColumn = {
      field: 'producerAndIdentityCardOrHPAndPhoneNumber',
      flex: 2,
      filtrable: false,
      sortable: true,
      headerName: translate('gridEventsProducerAndIdentityCardOrHPAndPhoneNumber'),
      renderCell: (params: any) => {
        return (
          <>
            <Box className={classes.StatusCell}>
              <Typography>
                <span>{translate('gridEventsCreatorName')}:</span> {params.row.creator.name} {" "} {params.row.creator.surname}
              </Typography>
              <Typography>
                <span>{translate('gridEventsCreatorAddress')}:</span> {params.row.creator.address}
              </Typography>
              <Typography>
              <span>{translate('gridEventsCreatorPhone')}:</span> {params.row.creator.phone}
              </Typography>
            </Box>
          </>
        );
      },
    }
    const datesColumns = [
      {
        field: 'startDate',
        flex: 1,
        filtrable: false,
        sortable: true,
        headerName: translate('gridEventsStartDate'),
        renderCell: (params: any) => {
          return (
            <Box className={classes.StatusCell}>
              <Typography>
                {format(new Date(params.row.startDate), "dd/MM/yyyy HH:mm")}
              </Typography>
            </Box>
          )
        }
      },
      {
        field: 'createDate',
        flex: 1,
        filtrable: false,
        sortable: true,
        headerName: translate('gridEventsCreateDate'),
        renderCell: (params: any) => {
          return (
            <Box className={classes.StatusCell}>
              <Typography>
                {format(new Date(params.row.createDate), "dd/MM/yyyy HH:mm")}
              </Typography>
            </Box>
          )
        }
      }
    ]
    const eventNameColumn = {
      field: 'name',
      flex: 1.6,
      filtrable: false,
      sortable: true,
      headerName: translate('gridEventsName'),
      renderCell: (params: any)=>{
        return (
          <>
            <Box className={classes.StatusCell}>
              <Typography>
                {params.row.name}
              </Typography>
              <Typography mt={"20px"}>
                <Button size='small' color='warning' onClick={()=>onOpenAsAdmin(params.row.id)}>
                  {translate('gridEventsOpenAsOwner')}
                </Button>
              </Typography>
            </Box>
          </>
        )
      }
    };
    //#endregion

    //#region Additional Columns
    const paymentStatusColumn = {
      field: 'paymentStatus',
      flex: 1.5,
      filtrable: false,
      sortable: true,
      headerName: translate('gridEventsPaymentStatus'),
      renderCell: (params: any) => {
          
        return (
          <>
          <Box className={classes.StatusCell}>
            <Button 
              disabled
              sx={{
                color: "#fc7f04",
              }}
              endIcon={
                params.row.isPaid
                ? 
                <CheckBoxIcon style={{marginRight: "10px"}}/> 
                : <CheckBoxOutlineBlankIcon style={{marginRight: "10px"}}/>
              }
              >
              {translate('gridEventsPaymentStatusIsPaidCheckBox')}
            </Button>
            <Typography>
              {translate('gridEventsOrderConfirmation')}
            </Typography>
            <Button sx={{
                  color: "#fc7f04",
                }}
              endIcon={<InsertDriveFileIcon style={{marginRight: "10px"}}/>}
              onClick={()=> {
                setPaymentModalEventData({
                  id: params.row.id,
                  link: params.row.receipt && params.row.receipt.link
                })
                dispatch(toggleAddPaymentModal())
              }}
              >
                {translate('gridEventsFile')}
            </Button>
            <Link 
            style={{cursor: "pointer"}} 
            color="#fc7f03" 
            onClick={()=>
              navigate(
                `documents`, {
                  state: {
                    id: params.row.id,
                    type: 'confirm',
                  }
                })
            }>
              <Typography>
                {translate('transactionsConfirm')} 
              </Typography>
            </Link>
            <Link 
            style={{cursor: "pointer"}} 
            color="#fc7f03" 
            onClick={()=>
              navigate(
                `documents`, {
                  state: {
                    id: params.row.id,
                    type: 'invoice',
                  }
                })
            }>
              <Typography>
                {translate('transactionsInvoices')} 
              </Typography>
            </Link>
            <Link 
            style={{cursor: "pointer"}} 
            color="#fc7f03" 
            onClick={()=>
              navigate(
                `documents`, {
                  state: {
                    id: params.row.id,
                    type: 'return',
                  }
                })
            }>
              <Typography>
                {translate('transactionsCancelled')} 
              </Typography>
            </Link>
          </Box>
          </>
      );
      },
    };
    const moneyStatisticColumns = [
      {
        field: 'totalTicketsPurchasedInCredit',
        flex: 1,
        filtrable: false,
        sortable: true,
        headerName: translate('gridEventsTotalTicketsPurchasedInCredit'),
      },
      {
        field: 'totalCommissionProfit',
        flex: 1,
        filtrable: false,
        sortable: true,
        headerName: translate('gridEventsTotalCommisionProfit'),
      },
      {
        field: 'totalTicketsSold',
        flex: 1,
        filtrable: false,
        sortable: true,
        headerName: translate('gridEventsTotalTicketsSold'),
      },
      {
        field: 'canceledMoney',
        flex: 1,
        filtrable: false,
        sortable: true,
        headerName: translate('gridEventsCanceledMoney'),
      },
      {
        field: 'canceledCount',
        flex: 1,
        filtrable: false,
        sortable: true,
        headerName: translate('gridEventsCanceledCount'),
      },
      {
        field: 'returnedMoney',
        flex: 1,
        filtrable: false,
        sortable: true,
        headerName: translate('gridEventsReturnedMoney'),
      },
      {
        field: 'returnedCount',
        flex: 1,
        filtrable: false,
        sortable: true,
        headerName: translate('gridEventsReturnedCount'),
      },
    ]
    
    //#endregion
    
    //#region API requests
    const [ eventsDataLazyFetch ] = eventApi.useLazyFetchEventsQuery()
    const {
      refetch: eventsDataRefetch,
      data: eventsData, 
      isFetching: isEventsFetching,
      isSuccess: isEventsSuccess,
    } = eventApi.useFetchEventsQuery({type: props.type})

    const [
      showEvent, 
      {isLoading: isShowLoading}
    ] = eventApi.useShowEventMutation();
    const [
      hideEvent, 
      {isLoading: isHideLoading}
    ] = eventApi.useHideEventMutation();
    const [
      resetEvent,
      {isLoading: isResetLoading}
    ] = eventApi.useResetEventMutation();
    const [
      deleteEvent, 
      {isLoading: isDeleteLoading}
    ] = eventApi.useDeleteEventMutation();
    const [
      confirmEvent, 
      {isLoading: isConfirmLoading}
    ] = eventApi.useConfirmEventMutation();
    const [ openAsAdminLazy ] = eventApi.useLazyOpenAsAdminQuery()
    //#endregion
    
    //#region Handlers
    function onLoadMoreClick(filter: string | undefined){
      eventsDataLazyFetch({ 
        type: props.type, 
        skip: rows.length, 
        take: takeCount,
        filter: filter
      })
      .then((response: any) => {
        const result = rows.concat(response.data.list)
        
        if(rows.length + takeCount > 80){
          result.splice(0, takeCount);
          toast.success(translate('hideRows'))
        }

        setRows(result)
        setCount(response.data.count)
      })
      .catch((errors: any) => console.error(errors))
    }
    function onFilterClick(filter: string | undefined){
      eventsDataLazyFetch({ 
        type: props.type, 
        filter: filter
      })
      .then((response: any) => {
        setRows(response.data.list)
        setCount(response.data.count)
      })
      .catch((errors: any) => console.error(errors))
    }
    function onReceiptChange(id: number, fileName: string){
      setRows(rows.map((event)=> {
        if(event.id === id) {
          return {...event, 
            isPaid: true,
            receipt: {
              link: fileName
            }
          }
        }
        else
          return event
      }
      ))
    }
    function onOpenAsAdmin(id: number){
      openAsAdminLazy({id: id})
      .then((response)=>{
        window.open((response.data as any).link);
      })
    }

    
    //need confirmation events
    function handleConfirmButonClick(clickedRow: any) {
      setChangedEventID(clickedRow.id)
      
      const updatedEventData = rows.map((x) => {
        if (x.id === clickedRow.id) {
          return {
            ...x,
            isNeedConfirmation: false
          };
        }
        return x;
      });
  
      confirmEvent(clickedRow.id)
      .unwrap()
      .then((result: any) => {
        dispatch(updateNotConfirmedCount({value: -1}))
        setCount(count => count = count -1)
        setRows(updatedEventData.filter(item => item.isNeedConfirmation === true))
        setChangedEventID(undefined)
      })
    }
    function handleDeleteButtonClick(clickedRow: any) {
      setChangedEventID(clickedRow.id)
      
      const updatedEventData = rows.map((x) => {
        if (x.id === clickedRow.id) {
          return {
            ...x,
            isDeleted: true
          };
        }
        return x;
      });
  
      deleteEvent(clickedRow.id)
      .unwrap()
      .then((result: any) => {
        dispatch(updateDeletedCount({value: 1}))
        dispatch(updateNotConfirmedCount({value: -1}))
        setCount(count => count = count -1)
        setRows(updatedEventData.filter(item => item.isDeleted !== true))
        setChangedEventID(undefined)
      })
    }

    //hidden events
    function handleShowButtonClick(clickedRow: any) {
      setChangedEventID(clickedRow.id)
      
      const updatedEventData = rows.map((x) => {
        if (x.id !== clickedRow.id) {
          return x;
        }
        else
          return {
            ...x,
            isHidden: false
          }
      });
  
      showEvent(clickedRow.id)
      .unwrap()
      .then((result: any) => {
        setRows(updatedEventData.filter(row => row.isHidden === true))
        setCount(count => count = count -1)
        dispatch(updateHiddenCount({value: -1}))
        setChangedEventID(undefined)
      })
      .catch((error: any) => console.error("ERROR", error))
    }

    function handleRepeatButtonClick(clickedRow: any) {
      setChangedEventID(clickedRow.id)

      const updatedEventData = rows.filter((x) => x.id !== clickedRow.id);

      resetEvent(clickedRow.id)
      .unwrap()
      .then((result: any) => {
          setRows(updatedEventData)
          dispatch(updateDeletedCount({value: -1}))
          setCount(count => count = count -1)
          setChangedEventID(undefined)
      })
      .catch((error: any) => console.error("ERROR", error))
    }

    //all and finished events
    function handleHideChange(clickedRow: any) {
      setChangedEventID(clickedRow.id)
      const updatedEventData = rows.map((x) => {
        if (x.id === clickedRow.id) {
          return {
            ...x,
            isHidden: !clickedRow.isHidden
          };
        }
        return x;
      });
  
      (clickedRow.isHidden ? 
        showEvent(clickedRow.id) : 
        hideEvent(clickedRow.id))
      .unwrap()
      .then((result: any) => {
        setRows(updatedEventData)
        dispatch(updateHiddenCount({value: clickedRow.isHidden ? -1 : 1}))
        setChangedEventID(undefined)
      })
      .catch((error: any) => console.error("ERROR", error))
    }
    function handleDeleteChange(clickedRow: any) {
      setChangedEventID(clickedRow.id)
      
      const updatedEventData = rows.map((x) => {
        if (x.id !== clickedRow.id) {
          return x;
        }
        else
          return {
            ...x,
            isDeleted: !x.isDeleted
          }
      });
  
  
      (!clickedRow.isDeleted ? 
        deleteEvent(clickedRow.id) : 
        resetEvent(clickedRow.id))
      .unwrap()
      .then((result: any) => {
        setRows(updatedEventData)
        dispatch(updateDeletedCount({value: clickedRow.isDeleted ? -1 : 1}))
        setChangedEventID(undefined)
      })
      .catch((error: any) => console.error("ERROR", error))
    }

    //#endregion 

    //#region UseEffect`s
    useEffect(()=>{
      if((eventsData||{}).list){
        setCount(eventsData.count)
        setRows(eventsData.list)
      }
    }, [eventsData, isEventsSuccess])

    useEffect(()=>{
        setIsAnyPutRequestLoading(
          isDeleteLoading || 
          isHideLoading || 
          isResetLoading || 
          isShowLoading
        )
    }, [isDeleteLoading, isHideLoading, isResetLoading, isShowLoading])

    // generating columns. Event data is always similiar in all, 
    // deleted, hidden, ... events. Only columns change all the time
    useEffect(()=>{
      let selectedColumns = [];
      
      if(columnSelector.withPaymentStatus) {
        selectedColumns.push(paymentStatusColumn);
      }
      
      selectedColumns.push(bankAccountInfoColumn)

      if(columnSelector.withMoneyStatistic){
        selectedColumns.push(...moneyStatisticColumns)
      }
      
      selectedColumns.push(producerInfoColumn)
      selectedColumns.push(...datesColumns)
      selectedColumns.push(eventNameColumn)
      selectedColumns.push({
        field: 'status',
        flex: 1.5,
        filtrable: false,
        sortable: true,
        headerName: translate('gridEventsStatus'),
        renderCell: (params: any)=>{
          const statusColor = params.row.status === 'active' ? StatusesHighlight.active :
          params.row.status === 'finished' ? StatusesHighlight.finished :
          params.row.status === 'deleted' ? StatusesHighlight.deleted : 'black' 

          return (
            <>
              <Box className={classes.StatusCell}>
                <Typography sx={{ color:  statusColor }}>
                  {params.row.status}
                </Typography>
                <Box sx={{marginTop: "20px"}}>
                  {columnSelector.statusColumnSelector.isDeletedCheckBox && (
                    <Button sx={{
                      color: "#fc7f04",
                    }}
                    disabled={isAnyPutRequestLoading}
                    endIcon={
                      (isDeleteLoading || isResetLoading) && params.row.id === changedEventID ? 
                      <CircularProgress size={20} style={{marginRight: "10px"}}/> : (
                        params.row.isDeleted as boolean 
                        ? 
                        <CheckBoxIcon style={{marginRight: "10px"}}/>
                        : <CheckBoxOutlineBlankIcon style={{marginRight: "10px"}}/>
                      )
                    }
                    onClick={()=>handleDeleteChange(params.row)}
                    >
                      {translate('gridEventsStatusDeleted')}
                    </Button>
                  )}
                  {columnSelector.statusColumnSelector.isHiddenCheckBox && (
                    <Button sx={{
                      color: "#fc7f04",
                    }}
                    disabled={isAnyPutRequestLoading}
                    endIcon={
                      (isHideLoading || isShowLoading) && params.row.id === changedEventID ? 
                      <CircularProgress size={20} style={{marginRight: "10px"}}/> : (
                        params.row.isHidden as boolean 
                        ? 
                        <CheckBoxIcon style={{marginRight: "10px"}}/>
                        : <CheckBoxOutlineBlankIcon style={{marginRight: "10px"}}/>
                      )
                    }
                    onClick={()=>handleHideChange(params.row)}
                    >
                      {translate('gridEventsStatusHidden')}
                    </Button>
                  )}
                  {columnSelector.statusColumnSelector.isShowButton && (
                    <Button 
                    sx={{color: "#fc7f04"}} 
                    disabled={isAnyPutRequestLoading}
                    endIcon={
                      (isShowLoading) && params.row.id === changedEventID ? 
                      <CircularProgress size={20} style={{marginRight: "10px"}}/> : (
                      <VisibilityIcon style={{marginRight: "10px"}}/>)
                    }
                    onClick={()=>handleShowButtonClick(params.row)}
                    >
                      {translate('gridEventsShowEvent')}
                    </Button>
                  )}
                  {columnSelector.statusColumnSelector.isConfirmButton && (
                    <Button 
                    sx={{color: "#fc7f04"}} 
                    disabled={isAnyPutRequestLoading}
                    endIcon={
                      isConfirmLoading && params.row.id === changedEventID ? 
                      <CircularProgress style={{marginRight: "10px"}}/> : 
                      <CheckIcon style={{marginRight: "10px"}}/>
                    }
                    onClick={()=>{handleConfirmButonClick(params.row)}}
                    >
                        {translate('gridEventsConfirmEvent')}
                    </Button>
                  )}
                  {columnSelector.statusColumnSelector.isDeleteButton && (
                    <Button 
                    sx={{color: "#fc7f04"}}
                    disabled={isAnyPutRequestLoading}
                    endIcon={
                      isDeleteLoading && params.row.id === changedEventID ? 
                      <CircularProgress style={{marginRight: "10px"}}/> : 
                      <DeleteIcon style={{marginRight: "10px"}}/>
                    }
                    onClick={()=>{handleDeleteButtonClick(params.row)}}
                    >
                        {translate('gridEventsDeleteEvent')}
                    </Button>
                  )}
                  {columnSelector.statusColumnSelector.isRepeatButton && (
                    <Button 
                    sx={{color: "#fc7f04"}}
                    onClick={()=>{handleRepeatButtonClick(params.row)}}
                    disabled={isAnyPutRequestLoading}
                    endIcon={
                      isResetLoading && params.row.id === changedEventID ? 
                      <CircularProgress size={20} style={{marginRight: "10px"}}/> : 
                      <ReplayIcon style={{marginRight: "10px"}} />
                    }>
                      {translate('gridEventsRepeatEvent')}
                    </Button>
                  )}
                </Box>
              </Box>
            </>
          )
        }
      })
      setColumns(selectedColumns)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      rows, 
      isAnyPutRequestLoading,
      columnSelector.statusColumnSelector.isConfirmButton,
      columnSelector.statusColumnSelector.isDeleteButton,
      columnSelector.statusColumnSelector.isDeletedCheckBox,
      columnSelector.statusColumnSelector.isHiddenCheckBox,
      columnSelector.statusColumnSelector.isRepeatButton,
      columnSelector.statusColumnSelector.isShowButton,
    ])

    useEffect(()=>{
      eventsDataRefetch()
      setColumnSelector({
        withPaymentStatus: props.type !== 'deleted' && props.type !== 'needConfirmation',
        withMoneyStatistic: props.type !== 'deleted' && props.type !== 'needConfirmation',
        statusColumnSelector: {
          isDeletedCheckBox: props.type !== 'deleted' && props.type !== 'needConfirmation',
          isHiddenCheckBox: props.type === 'all' || props.type === 'finished' || props.type === 'active', 
          isShowButton: props.type === 'hidden',
          isRepeatButton: props.type === 'deleted',
          isConfirmButton: props.type === 'needConfirmation',
          isDeleteButton: props.type === 'needConfirmation',
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.type])
    //#endregion

    if(isEventsFetching)
      return (
        <Box sx={{
          height: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <CircularProgress color="warning"/>
        </Box>
    )

    return (
      <>
      <Toaster position='bottom-right' toastOptions = {{duration: 4000}} />
      <div style={{position: "absolute", top: "0", width: "100%", paddingBottom: "70px"}}>
        <Filter 
          setFilterRequestString={setFilterRequestString} 
          fetchRequest={onFilterClick}
          sortByNameField={true}
        />
        <DataGrid
          sx={{
            backgroundColor: "#ede9e4",
            borderRadius: '0px',
            border: "none",
              
            '& .MuiDataGrid-virtualScroller': {
                marginTop: "0 !important"
            },
            '& .MuiDataGrid-main': {
                overflow: "visible"
            },
            '.MuiDataGrid-columnHeaders': {
              backgroundColor: "#fc7f03",
              position: "sticky",
              zIndex: 2,
              borderRadius: '0px',
              border: "0px",

            },
            '.MuiDataGrid-columnHeaderTitleContainer': {
              color: "#fff",
              fontSize: "15px",
              padding: '10px',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
            },
            '.MuiDataGrid-cell': {
              border: '1px solid #fc7f03',
              padding: '5px',
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
            },
            '.MuiDataGrid-footerContainer': {
              border: '1px solid #fc7f03',
            },
            '.MuiDataGrid-footerContainer p': {
              margin: '0px',
            },
            ".MuiDataGrid-columnHeaderTitle": {
              overflow: "visible",
              lineHeight: "1.43rem",
              whiteSpace: "normal",
            },
          }}
          rowCount={rows.length}
          // pageSize={(props: any) => {return 2}}
          rows={rows}
          columns={columns}
          getRowHeight={() => 'auto'}
          disableSelectionOnClick
          autoHeight
          density="comfortable"
          headerHeight={70}
          hideFooter
        />
        <Button 
        variant="contained" 
        color="warning"
        style={{
          borderRadius: "0px",
          boxShadow: "none"
        }}
        disabled={count <= rows.length}
        fullWidth 
        onClick={()=>onLoadMoreClick(filterRequestString)}
        size="large">
          {translate('customDataGridLoadMore')}
        </Button>
      </div>
      <AddPaymentFileModal id={paymentModalEventData.id} link={paymentModalEventData.link} onReceiptChange={onReceiptChange}/>
      </>
    )
}

export default EventsDataGrid;
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import AuthVerify from "../../../common/AuthVerify"
import { useAppSelector } from "../../../modules/store/hooks"
import { logOut, setCredentials } from "../../../modules/store/reducers/authSlice"

const RequireToken = () => {
    const dispatch = useDispatch()

    

    dispatch(setCredentials({accessToken: localStorage.getItem('token'), id: localStorage.getItem('id') }))
    const {token} = useAppSelector(state=> state.auth)
    const location = useLocation()
    
    const logout = useCallback(() => {
        dispatch(logOut());
    }, [dispatch]);

    return (
        <>
            {token ? 
            <Outlet /> : 
            <Navigate to="/login" state={{from: location}} replace />}
            <AuthVerify logOut={logout}/>
        </>
    )
}
export default RequireToken
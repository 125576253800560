import { Button, Link  } from '@mui/material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import useStyles from './useStyles';
import { useNavigate } from 'react-router-dom';
import DownloadProfilesExcel from '../../common/downloadProfilesExcel';
import translate from '../../i18n/translate';
import ProfilesDataGrid from '../UI/ProfilesDataGrid';
import { useState } from 'react';

const ProfilesScreen = () => {
  const classes = useStyles();
  const navigate = useNavigate()
  const [profileType, setProfileType] = useState<string>('all')
  const profileTypes = [
    {
      key: 'all',
      title: translate('profilesAllProfiles')
    },
    {
      key: 'deleted',
      title: translate('profilesDeletedProfiles')
    },
  ]

  function handleDownload(){
    DownloadProfilesExcel("he")
  }

  return(
      <div className={classes.Wrapper}>
            <div className={classes.StatusesList}>
              <Link 
              color="#fc7f03" 
              className={classes.ToMain} 
              mr={10} 
              ml="10px" 
              onClick={()=>navigate("/")}>
                {translate('commonBackToTheMainPage')}
              </Link>
              {profileTypes.map((item, index)=>(
                <Link 
                key={index} 
                mr={2} 
                style={{
                  cursor: "pointer",
                  color: "#fc7f03",
                  textDecorationColor: "#fc7f03",
                  textDecoration: item.key === profileType ? 'underline' : "none",
                  fontSize: "20px",
                }} 
                onClick={()=>setProfileType(item.key)}>
                  {item.title}
                </Link>
              ))}
              <div/>
              <Button 
              sx={{
                color: "black", 
                marginLeft: "150px"
              }} 
              startIcon={<SummarizeIcon sx={{color: "black"}}/>}
              onClick={()=>handleDownload()}
              >
                {translate("buttonDownloadAllInformation")}
              </Button>
          </div>
          <ProfilesDataGrid type={profileType}/>
      </div>
  )
}

export default ProfilesScreen;
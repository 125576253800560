import { useEffect } from "react";

function useOutsideAlerter(ref: any, setVisible: any) {
    useEffect(() => {
      
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setVisible(false)
        }
      }
      
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, setVisible]);
  }

export default useOutsideAlerter;
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
    StatusCell: {
        direction: "rtl",
        margin: "0",
        paddingRight: "5px",
        width: "100%",
        '& span': {
            color: "#fc7f03",
        }
    },
    StatusRow:  {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    TableHeader: {
        backgroundColor: "#fc7f03",
    },
}));

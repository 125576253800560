import { apiSlice } from "../store/api/adminApi"

export const statisticApi = apiSlice.injectEndpoints({
    endpoints: (build)=>({
        fetchStatistic: build.query<any, any>({
            query: () => ({
                url: `/admin/total/statistic/`
            }),
        }),
        fetchStatisticByDateRange: build.query<any, any>({
            query: () => ({
                url: `/admin/total/statistic/`
            }),
        }),
    })
})
// import style from './style.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography } from '@mui/material';
import useLockedBody from '../../../modules/hooks/useLockedBody';
import useStyles from './useStyles'

export type Props = {
  style?: React.CSSProperties
  children?: React.ReactNode
  title: any
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Modal(props: Props) {
  useLockedBody(props.visible)
  const style = useStyles()
  if (!props.visible) return <></>

  return (
    <>
      <div className={style.Background} onClick={()=>props.setVisible(false)}/>

      <div className={style.Modal} style={props.style}>
        <div className={style.Top}>
          <Typography width="fit-content" sx={{margin: 0}}>
            {props.title}
          </Typography>
          <IconButton 
          color="warning"
          className={style.Button}
          onClick={() => props.setVisible(false)}
          >
            <CloseIcon/>
          </IconButton>
        </div>
        <div style={{
          // maxHeight: "80vh",
          overflowY: "auto",
          }}>
          {props.children}
        </div>
      </div>
    </>
  )
}

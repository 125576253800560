const download = async (data: any, filename: any, type: any) => {
    const file = new Blob([data], { type });
    // Others
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  };
  
  export default download;
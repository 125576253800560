import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import logo from "../../../assets/images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../modules/store/hooks";
import { modalsSlice } from "../../../modules/store/reducers/modalsSlice";
import { Button, IconButton, Link } from "@mui/material";
import ActiveDevicesModal from "../../Modals/ActiveDevicesModal";
import SendOtpModal from "../../Modals/SendOtpModal";
import DevicesIcon from "@mui/icons-material/Devices";
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { statisticApi } from "../../../modules/services/statisticService";
import {
  logOut,
  toggleLanguage,
} from "../../../modules/store/reducers/authSlice";
import { LOCALES } from "../../../i18n";
import translate from "../../../i18n/translate";
import LogoutIcon from "@mui/icons-material/Logout";
import { deviceApi } from "../../../modules/services/deviceService";

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { toggleActiveDevicesModal, toggleSendOtpModal } = modalsSlice.actions;

  const [disconnectDevice] = deviceApi.useDeleteDeviceMutation();
  const [countOfConnectedDevices, setCountOfConnectedDevices] =
    useState<number>(0);
  const [statistic, setStatistic] = useState<any>({});
  const { language, sessionID } = useAppSelector((state) => state.auth);
  const onLanguageToggle = () => {
    dispatch(toggleLanguage());
  };

  const { data: statisticData } = statisticApi.useFetchStatisticQuery("");

  const getCurrentTitle = () => {
    switch (location.pathname) {
      case "/":
        return "Home page";
      default:
        return "Missing page";
      case "/not-confirmed":
        return "Waiting for confirm";
      case "/deleted":
        return "Deleted events";
      case "/finished":
        return "Finished events";
      case "/hidden":
        return "Hidden events";
      case "/all":
        return "All events";
      case "/all-invoices":
        return "All invoices";
      case "/profiles":
        return "Profiles";
      case "/deleted-profiles":
        return "Deleted Profiles";
    }
  };

  const logout = () => {
    disconnectDevice(sessionID)
      .then((response) => {
        dispatch(logOut());
        navigate("/login");
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (statisticData) setStatistic(statisticData);
  }, [statisticData]);

  return location.pathname === "/login" ? null : (
    <>
      <div className={style.Wrapper}>
        <div className={style.Logo}>
          <img
            alt="logo"
            src={logo}
            className={style.Image}
            style={{ borderRadius: "20px" }}
          />
          <div>
            <div className={style.Title}>
              SELECTOR
              <IconButton
                color="warning"
                className={style.LogOutButton}
                onClick={logout}
              >
                <LogoutIcon fontSize="large" />
              </IconButton>
            </div>
            <Button
              color="warning"
              className={style.AllDevices}
              onClick={() => dispatch(toggleActiveDevicesModal())}
              endIcon={<DevicesIcon />}
            >
              {countOfConnectedDevices}: {translate("headerDevicesConnected")}
            </Button>
            <Button
              color="warning"
              className={style.AllDevices}
              onClick={() => dispatch(toggleSendOtpModal())}
              endIcon={<SmsOutlinedIcon />}
            >
              {translate("headerOptToUsers")}
            </Button>
          </div>
        </div>
        <div className={style.Parameters}>
          {
            // location && location.pathname !== "/" ? (
            false ? (
              <div className={style.PageTitle}>{getCurrentTitle()}</div>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    marginTop: "8px",
                    marginRight: "50px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={onLanguageToggle}
                    disabled={language === LOCALES.ENGLISH}
                    style={{
                      width: "40px",
                      minWidth: "10px",
                      borderRadius: "0px",
                      boxShadow: "none",
                      height: "25px",
                    }}
                  >
                    EN
                  </Button>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={onLanguageToggle}
                    disabled={language === LOCALES.HEBREW}
                    style={{
                      width: "40px",
                      minWidth: "10px",
                      borderRadius: "0px",
                      boxShadow: "none",
                      height: "25px",
                    }}
                  >
                    HE
                  </Button>
                </div>
                {/* <div className={style.Column} style={{alignItems: language === LOCALES.HEBREW ? 'flex-end' : 'flex-start'}}>
                                        {/* <Link onClick={()=>navigate("/")} color="#fc7f03" style={{cursor: "pointer"}} className={style.Parameter}>
                                            {translate("headerOpenConfirmations")}
                                        </Link>
                                    </div> */}
                <div className={style.Column}>
                  <Link
                    onClick={() => navigate("/profiles")}
                    color="#fc7f03"
                    style={{ cursor: "pointer" }}
                    className={style.Parameter}
                  >
                    {translate("headerOpenProfiles")}
                  </Link>
                  <Link
                    onClick={() => navigate("/documents")}
                    color="#fc7f03"
                    style={{ cursor: "pointer" }}
                    className={style.Parameter}
                  >
                    {translate("headerOpenDocuments")}
                  </Link>
                </div>
                <div
                  className={style.Column}
                  style={{
                    width: "300px",
                    alignItems:
                      language === LOCALES.HEBREW ? "flex-end" : "flex-start",
                  }}
                >
                  <p className={style.Parameter}>
                    {statistic.total}₪: {translate("headerTotalMoney")}
                  </p>
                  <p className={style.Parameter}>
                    {statistic.totalFee}₪: {translate("headerTotalFee")}
                  </p>
                </div>
              </>
            )
          }
        </div>
      </div>
      <ActiveDevicesModal
        count={countOfConnectedDevices}
        setCount={setCountOfConnectedDevices}
      />
      <SendOtpModal />
    </>
  );
};

export default Header;

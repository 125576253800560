import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
    Wrapper: {
        height: "auto",
        width: "100%", 
        position: "relative",
    },
    ToMain: {
        fontSize: "20px",
        cursor: "pointer"
    },
    StatusesList: {
        zIndex: 10,
        position: "absolute",
        display: "flex",
        alignItems: "center",
        height: "70px",
        '& p': {
            fontSize: "larger",
        },
        '& span': {
            color: "orange",
            fontSize: "20px",
        }
    },
    StatusCell: {
        direction: 'rtl',
        margin: "0",
        paddingLeft: "5px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        '& span': {
            color: "#fc7f03",
        }
    },
    StatusRow:  {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    TableHeader: {
        backgroundColor: "#fc7f03",
    }
}));

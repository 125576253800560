import useStyles from "./useStyles"
import pdfIcon from '../../../assets/images/pdfIcon.png'

type Props = {
    name: string,

    onItemClick: () => void,
}

const FileItem = (props: Props) => {
    const styles = useStyles();

    return (
        <>
        <div className={styles.Wrapper} onClick={()=>{
            props.onItemClick()
        }}>
            <div style={{
                display: "flex",
                justifyContent: "center",
            }}>
                <img src={pdfIcon} alt={props.name} style={{height: "110px"}}/>
            </div>
            <div
                className={styles.WrappedText}
            >
                {props.name}
            </div>
        </div>
        </>
    )
}

export default FileItem;
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
    Wrapper: {
        cursor: 'pointer',
        width: "120px",
        margin: "10px",
        paddingTop: "5px",
        '&:hover': {
            backgroundColor: "lightblue",
            transition: "all .2s ease",
        }
    },
    LargeIcon: {
        width: 100,
        height: 100,
    },
    WrappedText: {
        textAlign: "center",
        padding: "5px",
        overflow: "hidden", 
        textOverflow: "ellipsis",
        display: '-webkit-box',
        '-webkit-line-clamp': "2",
        '-webkit-box-orient': "vertical",
    }
}));

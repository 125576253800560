import { IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

type Props = {
    key: any,
    item: any,
    onDisconnect: (id: number) => void,
    isDeletionDisabled: boolean
}

const DeviceItem = (props: Props) => {
    return (
        <div
        key={props.key}
        style={{
            position: "relative",
            backgroundColor: "white",
            marginBottom: "10px",
            padding: "10px 20px",

          }}>
            <Typography fontWeight={600}>
              {props.item.id}: {props.item.phone}
            </Typography>
            <Typography color="gray">
              {props.item.startDate}
            </Typography>
            <Typography fontWeight={600} mt={1}>
              ip: {props.item.ip}
            </Typography>
            <IconButton 
            disabled={props.isDeletionDisabled}
            onClick={()=>props.onDisconnect(props.item.id)}
            sx={{
              position: "absolute",
              right: "0",
              top: "0",
              width: "30px",
              height: "30px"}}>
              <CloseIcon />
            </IconButton>
          </div>
    )
}

export default DeviceItem;
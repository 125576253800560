import { DataGrid } from '@mui/x-data-grid';
import translate from '../../../i18n/translate';
import Filter from '../Filter';
import ReplayIcon from '@mui/icons-material/Replay';
import useStyles from './useStyles';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Button, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { userApi } from '../../../modules/services/userService';
import toast, { Toaster } from 'react-hot-toast';

type Props = {
  type?: string,
}

const ProfilesDataGrid = (props: Props) => {
    const takeCount = 10;
    const classes = useStyles();
    const [columns, setColumns] = useState<any[]>([]);
    const [isAnyPutRequestLoading, setIsAnyPutRequestLoading] = useState<boolean>(false)
    const [rows, setRows] = useState<any[]>([]);
    const [count, setCount] = useState<number>(0);
    const [filterRequestString, setFilterRequestString] = useState<string | undefined>(undefined);
    const [changedProfileID, setChangedProfileID] = useState<any | undefined>(undefined)
    
    //#region API requests
    const [ usersDataLazyFetch ] = userApi.useLazyFetchUsersQuery()
    const {
      refetch: usersDataRefetch,
      data: usersData, 
      isSuccess: isUsersSuccess,
      isFetching: isUsersFetching,
    } = userApi.useFetchUsersQuery({type: props.type})
    const [
      deleteUser,
      {isLoading: isDeleteLoading}
    ] = userApi.useDeleteUserMutation();
    const [
      resetUser,
      {isLoading: isResetLoading}
    ] = userApi.useResetUserMutation();
    const [
      openAsAdminLazy
    ] = userApi.useLazyOpenUserAsAdminQuery()
    //#endregion

    //#region Handlers
    function onOpenAsAdmin(id: number){
      openAsAdminLazy({id: id})
      .then((response)=>{
        window.open((response.data as any).link);
      })
    }

    function handleDeleteChange(clickedRow: any) {
      setChangedProfileID(clickedRow.id)
      
      const updatedUserData = rows.map((x) => {
        if (x.id !== clickedRow.id) {
          return x;
        }
        else
          return {
            ...x,
            isDeleted: !x.isDeleted
          }
      });
  
      (!clickedRow.isDeleted ? deleteUser(clickedRow.id) : resetUser(clickedRow.id))
      .unwrap()
      .then((result: any) => {
        setRows(updatedUserData)
        setChangedProfileID(undefined)
      })
      .catch((error: any) => console.error("ERROR", error))
    }
    function handleRestoreChange(clickedRow: any) {
      setChangedProfileID(clickedRow.id)
      
      const updatedEventData = rows.map((x) => {
        if (x.id !== clickedRow.id) {
          return x;
        }
        else
          return {
            ...x,
            isDeleted: false
          }
      });
  
      resetUser(clickedRow.id)
      .unwrap()
      .then((result: any) => {
        setRows(updatedEventData.filter((item)=>item.isDeleted === true))
        setCount(count=>count = count - 1)
        setChangedProfileID(undefined)
      })
      .catch((error: any) => console.error("ERROR", error))
    }
    function onLoadMoreClick(filter: string | undefined){
      usersDataLazyFetch({ 
        type: props.type, 
        skip: rows.length, 
        take: takeCount,
        filter: filter
      })
      .then((response: any) => {
        const result = rows.concat(response.data.list)
        
        if(rows.length + takeCount > 80){
          result.splice(0, takeCount);
          toast.success(translate('hideRows'))
        }

        setRows(result)
        setCount(response.data.count)
      })
      .catch((errors: any) => console.error(errors))
    }
    function onFilterClick(filter: string | undefined){
      usersDataLazyFetch({ 
        type: props.type, 
        filter: filter
      })
      .then((response: any) => {
        setRows(response.data.list)
        setCount(response.data.count)
      })
      .catch((errors: any) => console.error(errors))
    }
    //#endregion 

    //#region UseEffect`s
    useEffect(()=>{
      if(isUsersSuccess){
        setRows(usersData.list)
        setCount(usersData.count)
      }
    }, [usersData, isUsersSuccess])

    useEffect(()=> setIsAnyPutRequestLoading(isDeleteLoading), [isDeleteLoading])
    
    useEffect(()=>{
      let selectedColumns = [
        {
            field: 'totalClearances',
            flex: 1,
            filtrable: false,
            sortable: true,
            headerName: translate("gridProfilesTotalClearances"),
        },
        {
            field: "totalPurchases",
            flex: 1,
            filtrable: false,
            sortable: true,
            headerName: translate("gridProfilesTotalPurchases"),
        },
        {
            field: 'amountOfPurchasedTickets',
            flex: 1,
            filtrable: false,
            sortable: true,
            headerName: translate("gridProfilesAmountOfPurchasedTickets"),
        },
        {
            field: 'amountOfEvents',
            flex: 1,
            filtrable: false,
            sortable: true,
            headerName: translate("gridProfilesAmountOfEvents"),
        },
      ];
      
      selectedColumns.push({
        field: 'producerAndIdentityCardOrHPAndPhoneNumber',
        flex: 1,
        filtrable: false,
        sortable: true,
        headerName: translate("gridProfilesProducerAndIdentityCardOrHPAndPhoneNumber"),
        renderCell: (params: any) => {
        return (
            <>
            <Box className={classes.StatusCell}>
                <Typography>
                <span>{translate("gridProfilesCreatorOfTheEvent")}</span> {params.row.name} {params.row.surname}
                </Typography>
                <Typography>
                <span>{translate("gridProfilesPostalCode")}</span> {params.row.address}
                </Typography>
                <Typography>
                <span>{translate("gridProfilesCellphoneNumber")}</span> {params.row.phone}
                </Typography>
                <Typography mt={"20px"}>
                <Button size='small' color='warning' onClick={()=>onOpenAsAdmin(params.row.id)}>{translate("gridEventsOpenAsOwner")}</Button>
                </Typography>
                <br />
                {props.type === 'all' && (
                  <Button sx={{
                    color: "#fc7f04",
                  }}
                  disabled={isAnyPutRequestLoading}
                  endIcon={
                    isDeleteLoading && params.row.id === changedProfileID ? 
                    <CircularProgress size={20}/> : (
                      params.row.isDeleted as boolean 
                      ? 
                      <CheckBoxIcon style={{marginRight: "10px"}}/>
                      : <CheckBoxOutlineBlankIcon style={{marginRight: "10px"}}/>
                    )
                  }
                  onClick={()=>handleDeleteChange(params.row)}
                  >
                    {translate("gridProfilesDeletedUser")}
                  </Button>
                )}
                {props.type === 'deleted' && (
                  <Button 
                  sx={{ color: "#fc7f04" }}
                  disabled={isAnyPutRequestLoading}
                  endIcon={
                    isResetLoading && params.row.id === changedProfileID ? 
                    <CircularProgress size={20} style={{marginRight: "10px"}}/> : <ReplayIcon  style={{marginRight: "10px"}}/>
                  }
                  onClick={()=>handleRestoreChange(params.row)}
                  >
                    {translate('gridProfilesRestoreUser')}
                  </Button>
                )}
            </Box>
            </>
        );
        },
      } as any)

      setColumns(selectedColumns)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows, isAnyPutRequestLoading])

    useEffect(()=>{
      usersDataRefetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.type])
    //#endregion
    
    if(isUsersFetching)
      return (
        <Box sx={{
          height: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <CircularProgress color="warning"/>
        </Box>
    )
    return (
      <>
      <Toaster position='bottom-right' toastOptions = {{duration: 4000}} />
      <div style={{position: "absolute", top: "0", width: "100%", paddingBottom: "70px"}}>
        <Filter 
          setFilterRequestString={setFilterRequestString} 
          fetchRequest={onFilterClick}
          onlyPaidField={true}
          sortByNameField={true}
        />
        <DataGrid
          sx={{
            backgroundColor: "#ede9e4",
            borderRadius: '0px',
            border: "none",
              
            '& .MuiDataGrid-virtualScroller': {
                marginTop: "0 !important"
            },
            '& .MuiDataGrid-main': {
                overflow: "visible"
            },
              '.MuiDataGrid-columnHeaders': {
                backgroundColor: "#fc7f03",
                position: "sticky",
                zIndex: 2,
                borderRadius: '0px',
                border: "0px",

              },
              '.MuiDataGrid-columnHeaderTitleContainer': {
                color: "#fff",
                fontSize: "15px",
                padding: '10px',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
              },
              '.MuiDataGrid-cell': {
                border: '1px solid #fc7f03',
                padding: '5px',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
              },
              '.MuiDataGrid-footerContainer': {
                border: '1px solid #fc7f03',
              },
              '.MuiDataGrid-footerContainer p': {
                margin: '0px',
              },
              ".MuiDataGrid-columnHeaderTitle": {
                overflow: "visible",
                lineHeight: "1.43rem",
                whiteSpace: "normal",
              },
          }}
          pageSize={rows.length}
          rows={rows}
          columns={columns}
          getRowHeight={() => 'auto'}
          disableSelectionOnClick
          autoHeight
          density="comfortable"
          headerHeight={70}
          hideFooter
        />
        <Button 
        variant="contained" 
        color="warning"
        style={{
          borderRadius: "0px",
          boxShadow: "none"
        }}
        disabled={!(rows.length < count)}
        fullWidth 
        onClick={()=>onLoadMoreClick(filterRequestString)}
        size="large">
          {translate('customDataGridLoadMore')}
        </Button>
      </div>
      </>
    )
}

export default ProfilesDataGrid;
import { createSlice } from '@reduxjs/toolkit'

export const eventsCountSlice = createSlice({
  name: 'eventsCount',
  initialState: {
    all: 0,
    active: 0,
    notConfirmed: 0,
    hidden: 0,
    deleted: 0,
    canceled: 0,
    finished: 0,
    inProgress: 0,
    currentWeek: 0,
  },
  reducers: {
    setCount: (state, action) => {
      const {
          eventsCount, 
          deletedCount,
          finishedCount,
          hiddenCount,
          needConfirmationCount,
          canceledCount,
          activeCount,
          inProgressCount,
          currentWeekCount,
      } = action.payload;
      state.all = eventsCount; 
      state.notConfirmed = needConfirmationCount;
      state.hidden = hiddenCount;
      state.canceled = canceledCount;
      state.deleted = deletedCount;
      state.finished = finishedCount;
      state.active = activeCount;
      state.inProgress = inProgressCount;
      state.currentWeek = currentWeekCount;
    },
    updateAllCount: (state, action) => {
      const { value } = action.payload
      state.all = state.all + value
    },
    updateNotConfirmedCount: (state, action) => {
      const { value } = action.payload
      state.notConfirmed = state.notConfirmed + value
    },
    updateHiddenCount: (state, action) => {
      const { value } = action.payload
      state.hidden = state.hidden + value
    },
    updateDeletedCount: (state, action) => {
      const { value } = action.payload
      state.deleted = state.deleted + value
    },
    updateFinishedCount: (state, action) => {
      const { value } = action.payload
      state.finished = state.finished + value
    },
    updateActiveCount: (state, action) => {
      const { value } = action.payload
      state.active = state.active + value
    },
  },
})

export default eventsCountSlice.reducer

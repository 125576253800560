import useStyles from './useStyles';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Button, CircularProgress, IconButton, Typography } from '@mui/material';
import OtpInput from 'react-otp-input';
import 'react-phone-input-2/lib/style.css'
import CustomPhoneInput from '../UI/PhoneInput';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { RecaptchaVerifier, signInWithPhoneNumber} from 'firebase/auth'
import { auth } from '../../firebase.config';
import toast, { Toaster } from 'react-hot-toast';
import { setCredentials, toggleLanguage } from '../../modules/store/reducers/authSlice';
import { authApi } from '../../modules/services/authService';
import { useAppDispatch, useAppSelector } from '../../modules/store/hooks';
import { LOCALES } from '../../i18n';
import translate from '../../i18n/translate';

const LogIn = () => {
  const classes = useStyles();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const language = useAppSelector((state) => state.auth.language)

  const [isOTPLoading, setIsOTPLoading] = useState<boolean>(false)
  const [otp, setOtp] = useState<string>("");
  const [phn, setPhn] = useState<string>("");
  
  const [showOTP, setShowOTP] = useState<boolean>(false)
  const handleOtpChange = (otp: string) => setOtp(otp);
  const onLanguageToggle = () => dispatch(toggleLanguage());

  const [login] = authApi.useLoginMutation()

  const onCaptchVerify = (update: boolean = false) => {
    if(!(window as any).recaptchaVerifier || update)
      (window as any).recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
        callback: (response: any) => {
          onSignUp()
        },
        'expired-callback': () => {}
      }, auth);
  }

  const onSignUp = () => {
    setIsOTPLoading(true)
    onCaptchVerify()

    const appVerifier = (window as any).recaptchaVerifier
    const formatPhn = '+' + phn

    signInWithPhoneNumber(auth, formatPhn, appVerifier)
    .then((confirmationResult) => {
      (window as any).confirmationResult = confirmationResult;
      setIsOTPLoading(false)
      setShowOTP(true)
      toast.success(translate('loginCodeSendSuccess'))
    })
    .catch((error) => {
      toast.error(translate('loginError'))
      setIsOTPLoading(false)
    })
  }

  const onOTPVerify = async () => {
    try {
      setIsOTPLoading(true);

      const res = await (window as any).confirmationResult.confirm(otp)

      const response: any = 
      await login({token: res.user.accessToken})

      dispatch(setCredentials({accessToken: response.data.token, id: response.data.id}))

      setIsOTPLoading(false);
      navigation('/')
    } catch (err) {
      toast.error(translate('loginCodeInvalid'))
      setIsOTPLoading(false)
    }
  }
  
  useEffect(()=>{
    onCaptchVerify(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phn])

  return(
      <Box
        className={classes.Wrapper} 
      >
          <Box className={classes.LoginPanel}>
            <Toaster toastOptions = {{duration: 4000}} />
            <div id="recaptcha-container" />
              {showOTP ? (
                <>
                  <IconButton 
                  onClick={()=>setShowOTP(false)}
                  style={{
                    position: "absolute", 
                    top: "15px", 
                    left: "5px"
                  }}>
                    <ArrowBackIosNewIcon/> 
                  </IconButton>
                  <Typography className={classes.Headline}>
                    {translate('loginEnterCode')}
                  </Typography>
                  <OtpInput 
                  value={otp}
                  onChange={handleOtpChange}
                  inputStyle={{
                    width: "50px", 
                    height: "50px", 
                    color: "black", 
                    fontSize: "20px"
                  }} 
                  containerStyle={{
                    marginBottom: "10px",
                    width: "100%", 
                    display: "flex", 
                    justifyContent: "center"
                  }}
                  separator={<span style={{padding: "10px",fontSize: "20px"}}>-</span>}
                  numInputs={6}/>
                  <Button 
                  color="warning"
                  fullWidth
                  onClick={onOTPVerify}
                  variant="contained" 
                  startIcon={ isOTPLoading ? 
                    <CircularProgress 
                    size={25} 
                    style={{
                      position: "absolute", 
                      left:"10px", 
                      top: "5px", 
                      fontSize: "10px", 
                      color: "white"
                    }}/>
                    :
                    null
                  } 
                  style={{
                    marginTop: "10px",
                    position: "relative"
                  }}>
                    {translate('loginVerifyCode')}
                  </Button>
                </>
              ) : (
                <>
                  <Typography className={classes.Headline}>
                    {translate('loginEnterNumber')}
                  </Typography>
                  <CustomPhoneInput phone={phn} setPhone={setPhn}/>
                  <Button 
                  variant="contained" 
                  startIcon={ isOTPLoading ? 
                    <CircularProgress 
                    size={25} 
                    style={{
                      position: "absolute", 
                      left:"10px", 
                      top: "5px", 
                      fontSize: "10px", 
                      color: "white"
                    }}/>
                    :
                    null
                  } 
                  fullWidth
                  onClick={onSignUp}
                  color="warning"
                  style={{
                    marginTop: "10px",
                    position: "relative"
                  }}>
                    {translate('loginSendCode')}
                  </Button>
                </>
              )}
              <div style={{marginTop: "10px"}}>
              <Button 
              variant='contained' 
              color="warning" 
              onClick={onLanguageToggle}
              disabled={language === LOCALES.ENGLISH} 
              style={{
                  width: '40px', 
                  minWidth: '10px', 
                  borderRadius: '0px', 
                  boxShadow: "none", 
                  height: "25px"
              }}>
                  EN
              </Button>
              <Button 
              variant='contained' 
              color="warning" 
              onClick={onLanguageToggle}
              disabled={language === LOCALES.HEBREW} 
              style={{
                  width: '40px', 
                  minWidth: '10px', 
                  borderRadius: '0px', 
                  boxShadow: "none", 
                  height: "25px"
              }}>
                  HE
              </Button>
              </div>
            {/* EMAIL PASSWORD */}
            {/* {
              false &&
              <>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography fontSize="25px" sx={{width: "200px"}}>Login:</Typography>
                  <TextField fullWidth value={lgn} onChange={(e)=>onInputChange(e, setLgn)}/>
                </Box>
                <Box display="flex" sx={{marginTop: "10px"}} justifyContent="center" alignItems="center">
                  <Typography fontSize="25px" sx={{width: "200px"}}>Password:</Typography>
                  <TextField type="password" fullWidth value={pwd} onChange={(e)=>onInputChange(e, setPwd)}/>
                </Box>
                <Button 
                  fullWidth 
                  variant="contained" 
                  sx={{marginTop: "20px"}} 
                  onClick={onSubmit}
                  startIcon={ isOTPLoading ? 
                    <CircularProgress 
                    size={25} 
                    style={{
                      position: "absolute", 
                      left:"10px", 
                      top: "5px", 
                      fontSize: "10px", 
                      color: "white"
                    }}/>
                    :
                    null
                  } 
                >
                  SUBMIT
                </Button>
              </>
            } */}
          </Box>
      </Box>
  )
}

export default LogIn;
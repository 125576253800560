import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyB4L0bYe5hiyV2xpzBX35xRo0zGxe-bmiw",
  authDomain: "selector-fb083.firebaseapp.com",
  projectId: "selector-fb083",
  storageBucket: "selector-fb083.appspot.com",
  messagingSenderId: "742785507115",
  appId: "1:742785507115:web:33102544ed53b3fec36f6e",
  measurementId: "G-NR0038H6LB"
};

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)

import style from './style.module.scss'
import logo from './logo.png'
import confirm from './confirm-photo.png'

const Confirm = () => {
  const props = {
    dateOfTheDeal: 2,
    creditCardCustomerName: 3,
    id: 4,
    phoneNumber: 5,
    feeAmount: 6,
    amountOfBuyedTickets: 7,
    theNameOfTheEvent: 9,
    totalWithCommission: 9,
    lastFourNumbersOfCreditNumber: 9,
    amountOfPaidByCredit: 9999999999999,
    numberOfPage: 3
  }

  return (
    <>
      <div className={style.Receipt}>
        <div className={style.Wrapper}>
          <div>
            <div className={style.Header}>
              <div
                style={{
                  margin: '20px 20px 20px 20px',
                  display: 'flex'
                }}
              >
                <img src={logo} className={style.Logo} alt="logo"/>
                <h1 className={style.LogoTitle}>SELECTOR</h1>
              </div>
              <div
                style={{
                  margin: '20px 20px 20px 20px'
                }}
              >
                <span
                  style={{
                    fontSize: '20px',
                    textDecorationLine: 'underline'
                  }}
                >
                   סלקטור בע"מ - 
                  </span>
                  ע.מ: 2092922
              </div>
            </div>
            <div className={style.Main}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: '10px'
                  }}
                >
                  <p
                    style={{
                      textAlign: 'center',
                      margin: '0px',
                      fontSize: 'medium'
                    }}
                  >
                    {props.numberOfPage}: חשבונית מס קבלה
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      fontSize: 'large',
                      marginTop: '0',
                      fontWeight: 'bolder'
                    }}
                  >
                    <p
                      style={{
                        textDecorationLine: 'underline',
                        marginLeft: '20px',
                        marginTop: '0'
                      }}
                    >
                      מקור
                    </p>
                    <p
                      style={{
                        textDecorationLine: 'underline',
                        marginLeft: '20px',
                        marginTop: '0'
                      }}
                    >
                      {props.dateOfTheDeal}
                    </p>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  <p style={{ textAlign: 'right', margin: '0px' }}>
                  055-3199921 :טלפון
                    <br />
                    ('כתובת : ירושלים 29 צפת (קומהב
                    <br />
                    SELECTOR@GMAIL.COM : דוא"ל
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  marginTop: '50px'
                }}
              >
                <p
                  style={{
                    textAlign: 'right',
                    fontSize: '30px',
                    direction: 'rtl'
                  }}
                >
                  לכבוד:{' '}
                  <span style={{ textDecorationLine: 'underline' }}>
                    {props.creditCardCustomerName}
                  </span>
                  <br />
                  ע.מ ת.ז:{' '}
                  <span style={{ textDecorationLine: 'underline' }}>
                    {props.id}
                  </span>
                  <br />
                  מספר פלאפון:{' '}
                  <span style={{ textDecorationLine: 'underline' }}>
                    {props.phoneNumber}
                  </span>
                  <br />
                </p>
              </div>

              <table className={style.Table1}>
                <thead className={style.Table1Head}>
                  <tr>
                    <th
                      scope='col'
                      className={style.TableCell}
                      style={{ width: '100px' }}
                    >
                      סה"כ
                    </th>
                    <th scope='col' className={style.TableCell}>
                      כמות
                    </th>
                    <th scope='col' className={style.TableCell}>
                      תיאור
                    </th>
                    <th scope='col' className={style.TableCell}>
                      שם פריט \מק"ט
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={style.TableRow}>
                    <td className={style.TableCell}>{props.feeAmount}</td>
                    <td className={style.TableCell}>
                      {props.amountOfBuyedTickets}
                    </td>
                    <td className={style.TableCell}>
                      {props.theNameOfTheEvent}
                    </td>
                    <td className={style.TableCell}>עמלת רכישה</td>
                  </tr>
                </tbody>
              </table>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  marginTop: '100px'
                }}
              >
                <p
                  style={{
                    textAlign: 'right',
                    fontSize: '30px',
                    marginBottom: '0'
                  }}
                >
                  <span style={{ textDecorationLine: 'underline' }}>
                    :פרטי תשלום
                  </span>
                </p>
              </div>

              <table className={style.Table2}>
                <thead className={style.Table2Head}>
                  <tr>
                    <th
                      scope='col'
                      className={style.TableCell}
                      style={{ width: '200px' }}
                    >
                      סה"כ
                    </th>
                    <th scope='col' className={style.TableCell}>
                      כמות
                    </th>
                    <th scope='col' className={style.TableCell}>
                      פרטי תשלום
                    </th>
                    <th scope='col' className={style.TableCell}>
                      אשראי
                      <br />
                      <span style={{ fontSize: 'small', direction: 'rtl' }}>
                        (4 ספרות)
                      </span>
                    </th>
                    <th scope='col' className={style.TableCell}>
                      סוג תשלום
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={style.TableRow}>
                    <td className={style.TableCell}>
                      {props.totalWithCommission}
                    </td>
                    <td className={style.TableCell}>{props.dateOfTheDeal}</td>
                    <td className={style.TableCell}>תשלום 1</td>
                    <td className={style.TableCell}>
                      {props.lastFourNumbersOfCreditNumber}
                    </td>
                    <td className={style.TableCell}>
                      {props.amountOfPaidByCredit}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className={style.Footer}>
            <img src={confirm} className={style.Confirm} alt="confirm"/>
            <div
              style={{
                height: '70px',
                backgroundColor: '#FF9100',
                padding: '0'
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Confirm

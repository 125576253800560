import useStyles from './useStyles';
import FileExplorer from '../UI/FileExplorer';
import { Button, CircularProgress, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import { transactionApi } from '../../modules/services/transactionService';
import translate from '../../i18n/translate';
import { Box } from '@mui/system';
import Filter from '../UI/Filter';
import PDFReaderModal from '../Modals/PDFReaderModal';
import { modalsSlice } from '../../modules/store/reducers/modalsSlice';
import { useAppDispatch } from '../../modules/store/hooks';

const DocumentsScreen = () => {
    const location = useLocation();
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    const takeCount = 39;
    const {togglePdfViewerModal} = modalsSlice.actions;
    const [count, setCount] = useState<number>(0)
    const [viewerLink, setViewerLink] = useState<string>('')
    const [invoices, setInvoices] = useState<any[]>([])
    const [filterRequestString, setFilterRequestString] = useState<string | undefined>(undefined);
    const [type, setType] = useState('invoice')
    const [eventID, setEventID] = useState<undefined | number>(undefined)
    const documentTypes = [
        {
            key: 'invoice',
            title: translate('transactionsInvoices'),
        },
        {
            key: 'confirm',
            title: translate('transactionsConfirm'),
        },
        {
            key: 'return',
            title: translate('transactionsCancelled'),
        },
    ]


    const {
        data: transactionsData,
        refetch: transactionsDataRefetch,
        isFetching: isTransactionsFetching,
        isSuccess: isTransactionsSuccess,
        isError: isTransactionsError,
    } = transactionApi.useFetchTransactionsQuery({
        eventId: eventID, 
        take: takeCount, 
        type: type
    })
    const {
        refetch: getStatisticDataRefetch,
    } = transactionApi.useGetStatisticQuery('')
    
    const [ transactionDataLazyFetch, {isLoading: isTransactionsLazyLoading,} ] = transactionApi.useLazyFetchTransactionsQuery()

    function onDeviceClick(pdfUrl: string){
        // DownloadTransactionPDF(id)
        setViewerLink(pdfUrl)
        dispatch(togglePdfViewerModal())
    }

    function onLoadMoreClick(filter: string | undefined = undefined){
        transactionDataLazyFetch({
            eventId: eventID, 
            type: type,
            skip: invoices.length, 
            take: takeCount,
            filter: filter
        })
        .then((response: any) => {
            setInvoices(invoices.concat(response.data.list))
            setCount(response.data.count)
        })
        .catch((errors: any) => console.error(errors))
    }

    function onFilterClick(filter: string | undefined){
        transactionDataLazyFetch({ 
            eventId: eventID, 
            type: type,
            take: takeCount, 
            filter: filter
        })
        .then((response: any) => {
          setInvoices(response.data.list)
          setCount(response.data.count)
        })
        .catch((errors: any) => console.error(errors))
    }

    useEffect(()=>{
        if((location.state || {}).type)
            setType(location.state.type)
        if((location.state || {}).id)
            setEventID(location.state.id)
    }, [location.state])

    useEffect(()=>{
        transactionsDataRefetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])

    useEffect(()=>{
        if(!isTransactionsFetching){
            setInvoices(transactionsData.list)
            setCount(transactionsData.count)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionsData, isTransactionsSuccess])


    useEffect(()=>{
        transactionsDataRefetch()
        getStatisticDataRefetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const Fetching = () => {
        return (
            <Box sx={{
                height: "400px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <CircularProgress color="warning"/>
            </Box>
    )}   
    
    const NoInvoices = () => {
        return (
            <div style={{marginTop: '100px', display: "flex", justifyContent: "center", alignItems: "center"}}>
                <h1 >{translate('invoiceThisEventHasNoSuchFiles')}.{" "}
                    <Link 
                        color="#fc7f03"
                        className={classes.ToMain} 
                        style={{fontSize: "30px"}}
                        mt={0}
                        onClick={()=>navigate("/")}>
                            {translate('commonBackToTheMainPage')}
                    </Link>
                </h1>
            </div>
        )
    }
    
    const Error = () => {
        return (
            <div style={{marginTop: '100px', display: "flex", justifyContent: "center", alignItems: "center"}}>
                <h1 >{translate("transactionsFetchError")}.{" "}
                    <Link 
                        color="#fc7f03"
                        className={classes.ToMain} 
                        style={{fontSize: "30px"}}
                        mt={0}
                        onClick={()=>navigate("/")}>
                            {translate('commonBackToTheMainPage')}
                    </Link>
                </h1>
            </div>
        )
    } 

    return(
        <>
            <div className={classes.Wrapper}>
                <div className={classes.Head}>
                    <div className={classes.StatusesList}>
                        <Link 
                        color="#fc7f03" 
                        className={classes.ToMain} 
                        mr={10} 
                        ml="10px" 
                        onClick={()=>navigate("/")}>
                            {translate('commonBackToTheMainPage')}
                        </Link>
                        {documentTypes.map((item, index)=>(
                            <Link 
                            key={index} 
                            mr={2} 
                            style={{
                            cursor: "pointer",
                            color: "#fc7f03",
                            textDecorationColor: "#fc7f03",
                            textDecoration: item.key === type ? 'underline' : "none",
                            fontSize: "20px",
                            }} 
                            onClick={()=>setType(item.key)}>
                                {item.title}
                            </Link>
                        ))}
                        <div/>
                    </div>
                    {/* <div>
                        <div>
                            {translate('invoiceTotalInvoicesBeforeVAT')}: 350.000₪
                        </div>
                        <div>
                            {translate('invoiceTotalVAT')}: 59.000₪
                        </div>
                        <div>
                            {translate('invoiceTotalInvoicesIncluded')}: 409.500₪
                        </div>
                    </div> */}
                    <div>
                        <Filter
                        setFilterRequestString={setFilterRequestString} 
                        fetchRequest={onFilterClick}
                        />
                    </div>
                </div>
                {
                    isTransactionsFetching ? <Fetching /> : (
                        (isTransactionsError) ? <Error/> : (
                            (!invoices || invoices.length === 0) ? <NoInvoices /> : (<>
                                <FileExplorer items={invoices} onItemClick={onDeviceClick}/>
                                <Button
                                startIcon={isTransactionsLazyLoading ? 
                                    <CircularProgress size={25} 
                                    style={{
                                        position: "absolute", 
                                        left:"10px", 
                                        top: "5px", 
                                        fontSize: "10px", 
                                        color: "white"
                                    }}/> : null
                                }
                                disabled={!(invoices.length < count)}
                                variant='contained' 
                                color='warning'
                                onClick={()=>onLoadMoreClick(filterRequestString)}
                                >
                                    {translate('customDataGridLoadMore')}
                                </Button>
                                </>
                            )
                        )

                    )
                }
            </div>
            <PDFReaderModal link={viewerLink}/>
        </>
    )
}

export default DocumentsScreen;
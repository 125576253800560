import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
    Background: {
        position: "fixed",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
      
        backgroundColor: "rgba(0, 0, 0, 0.75)",
    },

    Modal: {
        width: "100%",
        maxWidth: "320px",
        minHeight: "200px",
        
        zIndex: "1000",
        
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        
        overflow: "hidden",
        borderRadius: "6px",
        backgroundColor: "white",
        overflowY: "initial",
    },
    
    Top: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 0px 0px 10px",
        width: "100%",
        height: "40px",
    },
  
    Button: {
        width: "40px",
        height: "40px",
    }
}));

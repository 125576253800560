import React, { useEffect, useState } from "react";
import { authApi } from "../modules/services/authService";

function parseJwt (token: string) {
 try{
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    
    return JSON.parse(jsonPayload);
  }
  catch (e) {
    console.error(e)
  }
}
const AuthVerify = (props: any) => {
  const [time, setTime] = useState(0)
  const [checkAuth] = authApi.useCheckTokenMutation()

  useEffect(() => {
    const user = localStorage.getItem("token");
    if (user) {
      const decodedJwt = parseJwt(user);
      checkAuth(user)
      .then((response: any)=>{
        if (decodedJwt.exp * 1000 < Date.now() || !response.data) {
          props.logOut();
        }
      })
    }
    setTimeout(() => {
      setTime(time+1)
    }, 600000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time]);

  return <></>;
};

export default AuthVerify;
import { Box } from "@mui/system";
import Modal from "../../Unknown/Modal";
import { useEffect, useState } from 'react'

import {Viewer, Worker} from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { useAppDispatch, useAppSelector } from "../../../modules/store/hooks";
import { modalsSlice } from "../../../modules/store/reducers/modalsSlice";

type Props = {
    link: string,
}

const PDFReaderModal = (props: Props) => {
    const [viewPdf, setViewPdf] = useState<string>('')
    const newplugin = defaultLayoutPlugin()

    const dispatch = useAppDispatch()
    const { togglePdfViewerModal } = modalsSlice.actions
    const visible = useAppSelector((state) => state.modalsReducer.pdfViewer)


    useEffect(()=> {
        if(props.link.length !== 0)
            setViewPdf(props.link)
    }, [props.link])

    return (
        <Modal 
        title="READER"
        visible={visible === 'open'}
        setVisible={()=>dispatch(togglePdfViewerModal())}
        style={{
            width: '800px',
            height: '900px',
            maxWidth: 'none',
            maxHeight: 'none'
        }}
        >
            <Box style={{
                width: "100%",
                height: '800px',
                overflowY: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js'>
                    {viewPdf && (<Viewer fileUrl={viewPdf} plugins={[newplugin] as any}/>)}
                    {!viewPdf && ("No PDF")}
                </Worker>
            </Box>
        </Modal>
    )
}

export default PDFReaderModal;
import { apiSlice } from "../store/api/adminApi";

export const eventApi = apiSlice.injectEndpoints({
    endpoints: (build)=>({
        fetchEvents: build.query<any, any>({
            query: (body: {
                type: string, 
                skip: number,
                take: number,
                filter: string
            }) => ({
                url: `/admin/events/list/${body.type}?skip=${body.skip || 0}&take=${body.take || 10}${body.filter || ""}`
            }),
        }),
        fetchEventsCount: build.query<any, any>({
            query: () => ({
                url: `/admin/events/count/`
            }),
        }),
        hideEvent: build.mutation({
            query: (id: string | number) => ({
                url: `/admin/events/hide/${id}`,
                method: 'PUT',
            }),
        }),
        showEvent: build.mutation({
            query: (id: string | number) => ({
                url: `/admin/events/show/${id}`,
                method: 'PUT',
            }),
        }),
        resetEvent: build.mutation({
            query: (id: string | number) => ({
                url: `/admin/events/reset/${id}`,
                method: 'PUT',
            }),
        }),
        confirmEvent: build.mutation({
            query: (id: string | number) => ({
                url: `/admin/events/confirm/${id}`,
                method: 'PUT',
            }),
        }),
        deleteEvent: build.mutation({
            query: (id: string | number) => ({
                url: `/admin/events/${id}`,
                method: 'DELETE',
            }),
        }),
        uploadFile: build.mutation<{}, {id: number, data: FormData}>({
            query({id, data}) {
              return {
                url: `/admin/events/pay/${id}`,
                method: 'POST',
                body: data,
              };
            },
        }),
        openAsAdmin: build.query<{}, {id: number}>({
            query: ({id}) => ({
                  url: `/admin/open/event/${id}`
            }),
        }),
    })
})
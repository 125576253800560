import Modal from "../../Unknown/Modal";
import { useAppDispatch, useAppSelector } from "../../../modules/store/hooks";
import { modalsSlice } from "../../../modules/store/reducers/modalsSlice";
import CustomPhoneInput from '../../UI/PhoneInput';
import { Box, Button, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import translate from "../../../i18n/translate";
import { userApi } from "../../../modules/services/userService";
import toast from "react-hot-toast";

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

const generateCode = () => {
  const firstCodeNum = getRandomInt(10);
  const secondCodeNum = getRandomInt(10);
  const thirdCodeNum = getRandomInt(10);
  const fourthCodeNum = getRandomInt(10);
  const fifthCodeNum = getRandomInt(10);
  const sixthCodeNum = getRandomInt(10);

  const code = `${firstCodeNum}${secondCodeNum}${thirdCodeNum}${fourthCodeNum}${fifthCodeNum}${sixthCodeNum}`

  return code;
}

export default function SendOtpModal() {
  const dispatch = useAppDispatch();
  const [phn, setPhn] = useState('+972')
  const { toggleSendOtpModal } = modalsSlice.actions;
  const visible = useAppSelector((state) => state.modalsReducer.sendOtp);

  const [
    sendOtp,
    { isLoading }
  ] = userApi.useSendOtpMutation();

  const optCode = useMemo(() => {
    return generateCode();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const sendAdminOtp = () => {
    sendOtp({ phone: phn, otp: optCode })
      .then(() => {
        navigator.clipboard.writeText(optCode);
        toast.success(translate('otpSetSuccess'))
        setTimeout(() => {
          dispatch(toggleSendOtpModal())
        }, 750)
        setPhn('+972');
      }).catch(() => {
        toast.error(translate('otpSetFailed'))
      })
  };

  const onCloseModal = () => {
    setPhn('+972');
    dispatch(toggleSendOtpModal());
  }

  return (
    <Modal
      title={translate("otpModalTitle")}
      visible={visible === "open"}
      style={{ maxWidth: "500px" }}
      setVisible={onCloseModal}
    >
      <Box my={2} p={2} display="flex" flexDirection="column" justifyContent="center" width={1} alignItems="center" gap={2}>
        <CustomPhoneInput phone={phn} setPhone={setPhn}/>
        <Typography variant="h3">{optCode}</Typography>
        <Button
          size="large"
          variant="contained"
          color="warning"
          onClick={sendAdminOtp}
          disabled={isLoading}
          >
          {translate("setOtp")}
        </Button>
      </Box>
    </Modal>
  );
}

import Modal from '../../Unknown/Modal'
import { useAppDispatch, useAppSelector } from '../../../modules/store/hooks'
import { modalsSlice } from '../../../modules/store/reducers/modalsSlice'
import { CircularProgress } from '@mui/material'
import { deviceApi } from '../../../modules/services/deviceService';
import { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import DeviceItem from '../../UI/DeviceItem';
import translate from '../../../i18n/translate';
import { logOut } from '../../../modules/store/reducers/authSlice';
import { useNavigate } from 'react-router-dom';

type Device = {
  id: number,
  ip: string,
  startDate: string,
  endDate: string,
}

type Props = {
  count: number,
  setCount: (arg: number) => void,
}

export default function ActiveDevicesModal({setCount, count}: Props) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { toggleActiveDevicesModal } = modalsSlice.actions
  const visible = useAppSelector((state) => state.modalsReducer.activeDevices)
  const {sessionID} = useAppSelector((state) => state.auth)
  
  const {data: loadedDevices, refetch: refetchLoadedDevices} = deviceApi.useFetchDevicesQuery('')
  const [disconnectDevice, {isLoading: isDisconnectLoading}] = deviceApi.useDeleteDeviceMutation()
  const [shownDevices, setShownDevices] = useState<Device[]>([])
  
  const onDisconnectDevice = (id: number) => {
    disconnectDevice(id)
    .then((response: any)=>{
      setShownDevices(shownDevices.filter((item) => item.id !== id))
      setCount(count-1)
      if(`${id}` === `${sessionID}`){
        navigate('/login')
        dispatch(logOut())
      }
      else
        toast.success(translate('devicesModalActionDisconnectSuccess'))
    })
    .catch((error)=>{
      toast.error(translate('devicesModalActionDisconnectFailure'))
    })
  }

  useEffect(()=>{
    if(loadedDevices){
      setCount(loadedDevices.list.length)
      setShownDevices(loadedDevices.list)
    }
  }, [loadedDevices, setCount])

  useEffect(()=>{
    if(window.location.pathname === '/')
      refetchLoadedDevices()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  return (
    <Modal
    title={translate('devicesModalTitle')}
    visible={visible === 'open'}
    style={{maxWidth: '400px'}}
    setVisible={() => dispatch(toggleActiveDevicesModal())}
    >
      <Toaster toastOptions = {{duration: 4000}}/>
      {!loadedDevices ? (
        <CircularProgress />
      ) : (
        <div style={{
          backgroundColor: "orange", 
          width: "100%",
          padding: "10px",
          // overflow: "hidden",
          }}>
          {
            shownDevices.map((item: Device, index)=>(
              <DeviceItem 
              key={index}
              item={item} 
              onDisconnect={onDisconnectDevice} 
              isDeletionDisabled={isDisconnectLoading}/>
            ))
          }
        </div>
      )}
    </Modal>
  );
}

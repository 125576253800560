import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'
type Props = {
    phone: string,
    setPhone: (arg: string) => void
}

const CustomPhoneInput = (props: Props) => {
    const onPhoneChange = (e: string) => {
        props.setPhone(e)
    }

    return (
        <>
            <PhoneInput
                inputStyle={{
                    fontSize: "20px",
                    width: "100%", 
                    padding: "28px 0 25px 60px"
                }} 
                buttonStyle={{
                    width: "50px",
                }}
                value={props.phone} 
                onChange={(e)=>onPhoneChange(e)}/>
        </>
    )
}
export default CustomPhoneInput;
import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GridFilterAltIcon, GridSearchIcon } from "@mui/x-data-grid";
import dayjs, { Dayjs } from "dayjs";
import { ChangeEvent, useRef, useState } from "react";
import translate from "../../../i18n/translate";
import useOutsideAlerter from "../../../modules/hooks/useOutsideAlterer";
import CustomDateRangePicker from "../CustomDateRangePicker";
import FilterItem from "../FilterItem";

type Props = {
  onlyPaidField?: boolean,
  sortByNameField?: boolean
  setFilterRequestString: (arg: string | undefined) => void
  fetchRequest: (filters: string | undefined) => void
}


const Filter = ({setFilterRequestString, fetchRequest: executeQuery, onlyPaidField, sortByNameField}: Props) => {
  const [isSortShown, setIsSortShown] = useState(false);
  const defaultFilterState = {
    onlyPaid: false,
    sortByName: false,
    ascending: false, 
    dateFrom: null,
    dateTo: null,
    searchField: "",
  }

  const wrappedRef = useRef(null);
  useOutsideAlerter(wrappedRef, setIsSortShown);
  
  const [filters, setFilters] = useState<any>(defaultFilterState)

  const onSearchFieldChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilters({...filters, searchField: event.target.value})
  }
  const onRestoreFilterClick = () => {
    setFilters(defaultFilterState)
    setFilterRequestString(undefined)
    executeQuery(undefined)
  }

  const changeFilterCheckBoxValue = (key: any) => {
    setFilters({...filters, [key]: !filters[key]});
  }
  const changeFilterDateStartValue = (value: Dayjs | null) => {
    setFilters({...filters, dateFrom: value});
  }
  const changeFilterDateEndValue = (value: Dayjs) => {
    setFilters({...filters, dateTo: value});
  }

  const onSearchClick = () => {
    let reqStr = `&${filters.sortByName ? "sortByName" : "sortByDate"}=${filters.ascending ? "ASC" : "DESC"}`
    

    if(filters.onlyPaid)
      reqStr += `&onlyPaid=true`

    if(filters.dateFrom && dayjs(filters.dateFrom, 'YYYY-MM-DD', true).isValid())
      reqStr+=`&dateFrom=${filters.dateFrom?.format('YYYY-MM-DD')}`

    if(filters.dateTo && dayjs(filters.dateTo, 'YYYY-MM-DD', true).isValid())
      reqStr+=`&dateTo=${filters.dateTo?.format('YYYY-MM-DD')}`

      
    if(filters.searchField && filters.searchField !== "")
      reqStr+=`&search=${filters.searchField}`
    
    executeQuery(reqStr)
    setFilterRequestString(reqStr)
  }
  const onFilterClick = async () => {
    let reqStr = `&${filters.sortByName ? "sortByName" : "sortByDate"}=${filters.ascending ? "ASC" : "DESC"}`
    

    if(filters.onlyPaid)
      reqStr += `&onlyPaid=true`

    if(filters.dateFrom && dayjs(filters.dateFrom, 'YYYY-MM-DD', true).isValid())
      reqStr+=`&dateFrom=${filters.dateFrom?.format('YYYY-MM-DD')}`

    if(filters.dateTo && dayjs(filters.dateTo, 'YYYY-MM-DD', true).isValid())
      reqStr+=`&dateTo=${filters.dateTo?.format('YYYY-MM-DD')}`
    
    executeQuery(reqStr)
    setFilterRequestString(reqStr)
  }

  return (
      <div style={{
          width: "100%", 
          display: "flex", 
          justifyContent: "end", 
          alignItems: "center",
          paddingRight: "10px",
          paddingBottom: "10px"
        }}>
          <div style={{position: "relative"}}>
            <Button
              onClick={()=> setIsSortShown(true)}
              sx={{
              display: "flex", 
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "5px",
            }}>
                <GridFilterAltIcon sx={{color: "orange"}}/>
                <Typography sx={{color: "orange"}} fontSize={15}>
                    {translate('filterTitle')}
                </Typography>
            </Button>
            { isSortShown && (
              <Box 
              ref={wrappedRef} 
              sx={{
                padding: "10px 10px 10px 10px", 
                backgroundColor: "white", 
                border: "1px solid orange", 
                position: "absolute", 
                top: 50, 
                left: 0, 
                zIndex: "100"
              }}>
                <CustomDateRangePicker 
                  dateFrom={filters.dateFrom}
                  setDateFrom={changeFilterDateStartValue}
                  dateTo={filters.dateTo}
                  setDateTo={changeFilterDateEndValue}
                />
                {onlyPaidField && (
                  <FilterItem 
                  value={filters.onlyPaid} 
                  setValue={changeFilterCheckBoxValue} 
                  code="onlyPaid" 
                  label={translate('filterItemOnlyPaid')} />
                )}
                {sortByNameField && (
                  <FilterItem 
                  value={filters.sortByName} 
                  setValue={changeFilterCheckBoxValue} 
                  code="sortByName" 
                  label={translate('filterItemSortByName')} />
                )}
                <FilterItem 
                value={filters.ascending} 
                setValue={changeFilterCheckBoxValue} 
                code="ascending" 
                label={translate('filterItemAscending')} />

                <Button fullWidth color="warning" onClick={onFilterClick}>
                  {translate('filterSubmit')}
                </Button>
                <Button fullWidth color="warning" onClick={onRestoreFilterClick}>
                  {translate('filterRestore')}
                </Button>
              </Box>
            )}
          </div>
          <Box sx={{
              width: "400px",
              height: "40px",
              border: "1px solid black",
              display: 'flex',
              alignItems: 'end',
              borderRadius: "5px",
              paddingLeft: "20px",
              position: "relative",
              borderColor: '#ED6C02'
            }}>
            <TextField 
            variant='standard'
            fullWidth
            value={filters.searchField}
            onChange={onSearchFieldChange}
            dir="rtl"
            sx={{
              paddingRight: "20px",
              '.MuiInputBase-root.MuiInput-root:before':{
                borderBottom: "0px"
              },
              '.MuiInputBase-root.MuiInput-root:hover':{
                borderBottom: "0px"
              },
            }}
            />
            <Button 
            size="small" 
            variant="contained"
            color="warning"
            onClick={onSearchClick}
            sx={{
              paddingX: "20px",
              height: "100%",
              borderRadius: "0px 3px 3px 0px",
              boxShadow: 'none'
            }}>
              {translate('filterSearch')}
              <GridSearchIcon sx={{
                top: "5px",
                height: "30px"
              }}/>
            </Button>
          </Box>
      </div>
  )
}

export default Filter;
import * as React from 'react';
import dayjs from "dayjs";
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import translate from '../../../i18n/translate';

type Props = {
  dateFrom?: Dayjs | null,
  setDateFrom: (arg: Dayjs | null) => void,
  dateTo?: Dayjs | null,
  setDateTo: (arg: Dayjs) => void,
}

export default function CustomDatePicker({
  dateFrom, 
  setDateFrom, 
  dateTo,
  setDateTo
}: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={translate('cdrpFrom')}
        value={dateFrom}
        disableOpenPicker
        onChange={(value) => {
          const date=dayjs(value,'MM-DD-YYYY')
          setDateFrom(date)
        }}
        mask="__/__/____"
        renderInput={(params) => 
          <TextField {...params} 
          dir="rtl"
          value={dateFrom}
          onChange={(event) => {
            const date=dayjs(event.target.value,'MM-DD-YYYY')
            setDateFrom(date)
          }}
        size="small"
        sx={{
          marginTop: '5px',
          width: "200px",
          marginRight: "10px"
        }} />}
      />

      <DatePicker
        label={translate('cdrpTo')}
        value={dateTo}
        disableOpenPicker
        onChange={() => {}}
        mask="__/__/____"
        renderInput={(params) => 
          <TextField {...params} 
          value={dateTo}
          dir="rtl"
          onChange={(event) => {
            const date=dayjs(event.target.value,'MM-DD-YYYY')
            setDateTo(date)
          }}
        size="small"
        sx={{
          marginTop: '15px',
          width: "200px",
          marginRight: "10px"
        }} />}
      />
    </LocalizationProvider>
  );
}
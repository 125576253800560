import { Checkbox, Typography } from "@mui/material";

type Props = {
    value: boolean,
    setValue: (value: any)=>void,
    label: any,
    code: string
}

const FilterItem = ({value, setValue, label, code}: Props) => {
    return (
        <div style={{
            width: "170px",
            display: "flex", 
            alignItems: "center", 
            }}>
            <Checkbox checked={value} onClick={()=> {setValue(code)}}/>
            <Typography>
              {label}
            </Typography>
          </div>
    )
}

export default FilterItem;
import { apiSlice } from "../store/api/adminApi"

export const userApi = apiSlice.injectEndpoints({
    endpoints: (build)=>({
        fetchUsers: build.query<any, any>({
            query: (body: {
                type: string, 
                skip: number,
                take: number,
                filter: string
            }) => ({
                url: `/admin/users/list/${body.type}?skip=${body.skip || 0}&take=${body.take || 10}${body.filter || ""}`
            }),
        }),
        getUser: build.query<any, string>({
            query: (id: string) => ({
                url: `/admin/users/${id}`
            })
        }),
        deleteUser: build.mutation({
            query: (id: string | number) => ({
                url: `/admin/users/${id}`,
                method: 'DELETE',
            }),
        }),
        resetUser: build.mutation({
            query: (id: string | number) => ({
                url: `/admin/users/reset/${id}`,
                method: 'PUT',
            }),
        }),
        openUserAsAdmin: build.query<{}, {id: number}>({
            query: ({id}) => ({
                  url: `/admin/open/user/${id}`
            }),
        }),
        sendOtp: build.mutation({
            query: (body: { phone: string, otp: string }) => ({
                url: `/admin/users/opt/generate`,
                method: 'POST',
                body: {
                    phone: `+${body.phone}`,
                    otp: body.otp
                }
            }),
        }),
    })
})
import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Header from '../Header';

type Props = {
    children: JSX.Element,
};

const AppLayout: React.FC<Props> = ({ children }: Props) => {
  return (
    <>
      <Header />
      <Container style={{width: "100%", maxWidth: "100%", padding: 0}}>
        <Box
          display="flex"
          justifyContent="center"
          // py={{xs: 2}}
        >
          {children}
        </Box>
      </Container>
    </>
  );
};
export default AppLayout;

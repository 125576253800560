import download from "./download";
import axios from 'axios'

async function DownloadProfilesExcel (language: string) {
    const token = localStorage.getItem('token')
    try {
        const url = `https://api.selector.org.il/admin/users/export?language=${language}`;
        axios({
          url: url,
          method: "GET",
          responseType: 'blob',
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((response: any) => {
            download(
                response.data,
                `profiles (${language})`,
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              );
        })
      } catch (error) {
        console.error("File missing")
      }
}

export default DownloadProfilesExcel;
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
    Wrapper: {
        marginTop: "20px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    StatusesList: {
        zIndex: 10,
        // position: "absolute",
        display: "flex",
        height: "70px",
        '& p': {
            fontSize: "larger",
        },
        '& span': {
            color: "orange",
            fontSize: "20px",
        }
    },
    ToMain: {
        fontSize: "20px",
        cursor: "pointer"
    },
    Head: {    
        display: "flex",
        justifyContent: "space-between",
    }
}));

